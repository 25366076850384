import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Path } from '../types/path'
import BensonLogo from '../assets/logo-catalogue.png'

const Header: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const matchedPath = matchPath({ path: '/:key', end: false }, pathname)
  const key = matchedPath?.params.key || ''

  if (window.location.pathname === Path.NotFound) {
    return null
  }

  return (
    <Container>
      <Logo src={BensonLogo} onClick={() => navigate(`/${key}${Path.Home}`)} />
    </Container>
  )
}

export default Header

const Logo = styled.img`
  width: 400px;
  margin: 30px 0;
  cursor: pointer;

  @media only screen and (max-width: 799px) {
    width: max(min(50%, 340px), 340px);
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  /* min-width: 800px; */
  padding: 0 4%;
  box-sizing: border-box;

  @media only screen and (min-width: 599px) {
    padding: 0 max(50px, 10%);
  }

  @media only screen and (min-width: 799px) {
    padding: 0 max(50px, 15%);
  }
`
