import React from 'react'
import AcknowledgePopup from './AcknowledgePopup'
import ConfirmPopup from './ConfirmPopup'
import LoadingPopup from './LoadingPopup'
import ProductDetailPopup from './ProductDetailPopup'

export enum PopupTypes {
  PRODUCT_DETAIL_POPUP = 'PRODUCT_DETAIL_POPUP',
  LOADING_POPUP = 'LOADING_POPUP',
  CONFIRM_POPUP = 'CONFIRM_POPUP',
  ACKNOWLEDGE_POPUP = 'ACKNOWLEDGE_POPUP',
}

const popupTypes: { [key: string]: React.FC<any> } = {
  [PopupTypes.PRODUCT_DETAIL_POPUP]: ProductDetailPopup,
  [PopupTypes.LOADING_POPUP]: LoadingPopup,
  [PopupTypes.CONFIRM_POPUP]: ConfirmPopup,
  [PopupTypes.ACKNOWLEDGE_POPUP]: AcknowledgePopup,
}

export default popupTypes
