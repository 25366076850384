import directus, { checkError } from './directus'

export interface CatalogueTable {
  highlight: Record<string, any>[]
  offer: Record<string, any>[]
  ring: Record<string, any>[]
  necklace: Record<string, any>[]
  earring: Record<string, any>[]
  bracelet: Record<string, any>[]
  pendant: Record<string, any>[]
}

export const getProductData = async (table: string, fields: string[] = [], offset?: number, tags?: string) => {
  try {
    const directusFields: string[] = []
    fields.forEach((field) => {
      directusFields.push(`${field}.*`, `${field}.tags.tag_id`, `${field}.style_group.related_styles.*`)
    })

    const field = fields[0]?.split('.')?.[0] || ''

    const res = await directus.items<string, CatalogueTable>(table).readMany({
      fields: directusFields,
      // limit: 20,
      // offset,
      // ...(tags && { search: tags }),
      deep: { [field]: { _limit: 20, _offset: offset, _sort: 'id' } as any },
    })
    return res.data as unknown as CatalogueTable
  } catch (e) {
    checkError(e)
    throw e
  }
}
