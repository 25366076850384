import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { matchPath } from 'react-router'
import { clearOffers, clearProducts, setUserInfo } from '../actions/data'
import { openPopup } from '../actions/ui'
import { PopupTypes } from '../components/popup'
import { getNavCategory } from '../services/getNavCategory'
import getUserInfo from '../services/getUserInfo'
import login from '../services/login'
import { Path } from '../types/path'
import { wait } from '../utilities/wait'

const WithUserInfo: React.FC = ({ children }) => {
  const pathname = window.location.pathname
  const [loading, setLoading] = useState(pathname === Path.NotFound ? false : true)
  const dispatch = useDispatch()

  const matchedPath = matchPath({ path: '/:key', end: false }, pathname)
  const key = matchedPath?.params.key || ''

  useEffect(() => {
    ;(async () => {
      try {
        if (loading === true) {
          dispatch(openPopup({ type: PopupTypes.LOADING_POPUP }))
          await wait()
          await login(key)
          const user = await getUserInfo(key)
          const offer = !!user?.tags?.includes('offer')
          const activeCategory = offer ? [] : (await getNavCategory(user?.table)).active_category || []

          const persistedConfig = JSON.parse(localStorage.getItem('persist:root') || '{}').user

          if (!persistedConfig?.includes(key)) {
            offer && dispatch(clearOffers())
            !offer && dispatch(clearProducts())
            // await persistor.purge()
          }

          const [description, expire] = user?.description?.split(',')

          dispatch(
            setUserInfo({
              ...(offer ? { offerKey: key } : { productKey: key }),
              spot: Number(description?.slice(0, 2)) || 0,
              discount: Number(description?.slice(2)) || 0,
              expire: expire || '',
              offer,
              table: user?.table,
              activeCategory: activeCategory,
            }),
          )
          setLoading(false)
        }
      } catch {}
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return null
  }

  return <>{children}</>
}

export default WithUserInfo
