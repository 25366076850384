import * as R from 'ramda'
import { CLEAR_OFFER, PURGE_OFFER, TOGGLE_OFFER } from '../actions/constants'
import { ProductDetailPopupProps } from '../components/popup/ProductDetailPopup'

export interface PayloadProps {
  type: string
  payload: ProductDetailPopupProps
}

const initialState: Record<string, ProductDetailPopupProps> = {}

const offerReducer = (state = initialState, { type, payload }: PayloadProps) => {
  switch (type) {
    case TOGGLE_OFFER: {
      const keys = R.keys(state)
      const newState = { ...state }
      const { id } = payload

      return keys.includes(id) ? R.dissoc(id, newState) : R.assoc(id, payload, newState)
    }
    case PURGE_OFFER:
    case CLEAR_OFFER:
      return initialState
    default:
      return state
  }
}

export default offerReducer
