import React, { useCallback, useEffect, useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import Product, { ProductProps } from '../components/Product'
import { useDispatch, useSelector } from 'react-redux'
import { toggleItem, toggleOfferItem } from '../actions/data'
import { getStoredUserInfo } from '../utilities/selectors/getStoredUserInfo'
import { getProductData } from '../services/getProductData'
import { CatalogueFields } from '../types'
import { normalizeTableData } from '../utilities/normalizeTableData'
import { closePopup, openPopup } from '../actions/ui'
import { PopupTypes } from '../components/popup'
import { wait } from '../utilities/wait'
import Subheader from '../components/Subheader'

export type ProductConfig = Omit<ProductProps, 'toggle' | 'columns'> & {
  tags: { tag_id: number }[]
}

const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [products, setProducts] = useState<{ new: ProductConfig[] }>({
    new: [],
  })

  const dispatch = useDispatch()
  const { table = '', offer = false, spot, discount, expire } = useSelector(getStoredUserInfo)

  useEffect(() => {
    ;(async () => {
      try {
        if (offer) {
          dispatch(openPopup({ type: PopupTypes.LOADING_POPUP }))
          await wait()
          const data = await getProductData(table, [CatalogueFields.OFFER])

          setProducts({
            new: normalizeTableData(
              offer ? data.offer : data.highlight,
              `${R.last(R.split('.', CatalogueFields.OFFER))}`,
              discount,
              spot,
            ),
          })
        }
      } finally {
        setLoading(false)
        dispatch(closePopup())
      }
    })()
  }, [])

  const handleAdd = useCallback((product: ProductConfig) => {
    dispatch(offer ? toggleOfferItem(product) : toggleItem(product))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return null
  }

  return (
    <>
      {!offer ? (
        <>
          <Subheader>Disclaimer</Subheader>
          {/* <Text>The collection shown on this website is curated and does not represent our full catalogue.</Text>
          <br /> */}
          <Text>
            The information provided on this website is confidential and intended solely for the use of the intended
            recipient(s). Unauthorized use, copying, disclosure, or distribution of the information contained on this
            website is strictly prohibited. If you have received this information in error, please notify the sender
            immediately.
          </Text>
          {/* <br />
          <ExpireDisclaimer>
            This access link expires on{' '}
            {new Date(expire).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}
          </ExpireDisclaimer> */}
        </>
      ) : (
        <Row>
          {products.new.map((product) => {
            return <Product {...product} key={product.id} toggle={() => handleAdd(product)} />
          })}
        </Row>
      )}
    </>
  )
}

export default React.memo(Home, () => true)

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px 0px -2px;
`

const Text = styled.div`
  font-size: ${(props) => props.theme.font.large};
  margin-bottom: 5px;
`

const ExpireDisclaimer = styled.div`
  font-size: ${(props) => props.theme.font.large};
  color: ${({ theme }) => theme.colors.primary};
  opacity: 60%;
`
