import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { closePopup } from '../../actions/ui'

const PopupBase: React.FC<{ allowClose?: boolean }> = ({ children, allowClose = true }) => {
  const dispatch = useDispatch()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    if (allowClose) {
      const handleEsc = (event: any) => {
        if (event.keyCode === 27) {
          dispatch(closePopup())
        }
      }
      window.addEventListener('keydown', handleEsc)

      return () => {
        window.removeEventListener('keydown', handleEsc)
      }
    }
  }, [])

  return (
    <BackgroundContainer show={mounted} onClick={() => allowClose && dispatch(closePopup())}>
      <Wrapper onClick={(e) => e.stopPropagation()}>{children}</Wrapper>
    </BackgroundContainer>
  )
}

export default PopupBase

const BackgroundContainer = styled.div<{ show: boolean }>`
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.popup_bg};
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  touch-action: none;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: 0.3s opacity;
`

const Wrapper = styled.div`
  display: contents;
`
