import directus, { checkError } from './directus'

const getUserInfo = async (key: string) => {
  try {
    const res = await directus.users.me.read({
      filter: {
        id: {
          _eq: key,
        },
      },
    })

    return { tags: res?.tags || [], table: res?.location, description: res?.description }
  } catch (e) {
    checkError(e)
    throw e
  }
}

export default getUserInfo
