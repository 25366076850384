import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Player } from '@lottiefiles/react-lottie-player'
import { closePopup } from '../actions/ui'
import Astronaut from '../assets/astronaut.json'

const NotFound: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(closePopup())
  }, [])

  return (
    <Container>
      <Player autoplay loop src={Astronaut} style={{ height: '300px', width: '300px' }}></Player>
      <NotFoundText>Access Link Invalid or Expired.</NotFoundText>
      <NotFoundBody>
        <span style={{ whiteSpace: 'nowrap' }}>
          Please contact our support team <ContactForm href="https://bensonjewelry.co.th/contact">here</ContactForm>
        </span>
      </NotFoundBody>
    </Container>
  )
}

export default NotFound

const ContactForm = styled.a`
  font-weight: 700;
  border-bottom: 1px solid #9c9c9c;
  opacity: 90%;
  color: #787878;

  transition: opacity 0.2s;
  &:hover {
    opacity: 60%;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  position: fixed;
  z-index: 10;
  background-color: #fffefe;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  touch-action: none;
  overflow: hidden;
`

const NotFoundText = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
  padding: 0 10px;
`

const NotFoundBody = styled.div`
  font-size: 15px;
  /* font-weight: 700; */
  text-align: center;
  padding: 0 10px;
`
