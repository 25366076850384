import { CLOSE_POPUP, OPEN_POPUP } from './constants'

export const openPopup = ({ type, payload }: { type: string; payload?: Record<string, any> }) => ({
  type: OPEN_POPUP,
  payload: {
    type,
    payload,
  },
})

export const closePopup = () => ({
  type: CLOSE_POPUP,
})
