import { Navigate, useParams } from 'react-router-dom'
import { CatalogueFields, TagTypes } from '../types'
import { MainCatalogueProps } from '../containers/MainCatalogue'

const catalogueConfig: Record<string, MainCatalogueProps> = {
  rings: {
    catalogueField: CatalogueFields.RING,
    tagType: TagTypes.RING,
    title: 'Rings',
    catalogueTable: 'ring',
  },
  necklaces: {
    catalogueField: CatalogueFields.NECKLACE,
    tagType: TagTypes.NECKLACE,
    title: 'Necklaces',
    catalogueTable: 'necklace',
  },
  bracelets: {
    catalogueField: CatalogueFields.BRACELET,
    tagType: TagTypes.BRACELET,
    title: 'Bracelets',
    catalogueTable: 'bracelet',
  },
  earrings: {
    catalogueField: CatalogueFields.EARRING,
    tagType: TagTypes.EARRING,
    title: 'Earrings',
    catalogueTable: 'earring',
  },
  pendants: {
    catalogueField: CatalogueFields.PENDANT,
    tagType: TagTypes.PENDANT,
    title: 'Pendants',
    catalogueTable: 'pendant',
  },
}

const withCatalogueRoutes =
  (Component: React.FC<MainCatalogueProps>): React.FC =>
  () => {
    const { category = '' } = useParams()

    if (!(category in catalogueConfig)) {
      return <Navigate to="/404" /> //SHOULD navigate to 404
    }

    return <Component key={category} {...catalogueConfig[category]} />
  }

export default withCatalogueRoutes
