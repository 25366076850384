import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'
// @ts-ignore
import { slide as Menu } from 'react-burger-menu'
import { State } from '../types'
import { Path } from '../types/path'
import { getAddedProducts } from '../utilities/selectors/getAddedProducts'
import { getStoredUserInfo } from '../utilities/selectors/getStoredUserInfo'

const navConfig = [
  { title: 'EARRINGS', path: Path.Earrings },
  { title: 'BRACELETS', path: Path.Bracelets },
  { title: 'RINGS', path: Path.Rings },
  { title: 'NECKLACES', path: Path.Necklaces },
  { title: 'PENDANTS', path: Path.Pendants },
]

const NavBarContainer: React.FC = ({ children }) => {
  const isCurrentSticky = window.scrollY > 300
  const [isSticky, setIsSticky] = useState(isCurrentSticky)

  useEffect(() => {
    const setSticky = () => {
      setIsSticky(window.scrollY > 200)
    }
    window.addEventListener('scroll', setSticky, false)

    return () => {
      window.removeEventListener('scroll', setSticky)
    }
  }, [])

  return (
    <>
      <StickyContainer>
        <Container>
          {children}
          <ShadowContainer visible={isSticky} />
        </Container>
      </StickyContainer>
      <div style={{ marginBottom: '40px' }} />
    </>
  )
}

const NavBar: React.FC = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const {
    offer = false,
    count,
    activeCategory,
  } = useSelector((state: State) => {
    const user = getStoredUserInfo(state)
    return { offer: user.offer, count: getAddedProducts(state), activeCategory: user.activeCategory }
  })
  const matchedPath = matchPath({ path: '/:key', end: false }, pathname)
  const key = matchedPath?.params.key || ''
  const lastRef = useRef('')
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (pathname === Path.NotFound) {
    return null
  }

  if (pathname === `/${key}${Path.Summary}`) {
    return (
      <NavBarContainer>
        <Row>
          <NavLink onClick={() => navigate(lastRef.current || `/${key}${Path.Home}`)}>{'< BACK'}</NavLink>
        </Row>
      </NavBarContainer>
    )
  }

  const categoryNav = (onClick = () => {}) =>
    !offer &&
    navConfig.map((config) => {
      return (
        activeCategory.includes(config.title.toLocaleLowerCase()) && (
          <NavLink
            key={config.path}
            selected={pathname === config.path}
            onClick={() => {
              onClick()
              navigate(`/${key}${config.path}`)
            }}
          >
            {config.title}
          </NavLink>
        )
      )
    })

  return (
    <NavBarContainer>
      <CategoryRow>{categoryNav()}</CategoryRow>
      <BurgerMenuWrapper>
        <Menu isOpen={isOpen} onStateChange={(state: any) => setIsOpen(state.isOpen)}>
          {categoryNav(() => setIsOpen(false))}
        </Menu>
        <span style={{ padding: '0 10px' }}>MENU</span>
      </BurgerMenuWrapper>
      <Row>
        {/* <NavLink
          style={{ position: 'relative' }}
          onClick={() => {
            lastRef.current = pathname + search
            navigate(`/${key}${Path.Summary}`)
          }}
        >
          SUMMARY
          <CartCount visible={count.length !== 0}>{count.length}</CartCount>
        </NavLink> */}
      </Row>
    </NavBarContainer>
  )
}

export default NavBar

const Row = styled.div`
  display: flex;
`

const CategoryRow = styled(Row)``

const BurgerMenuWrapper = styled.div`
  padding: 20px;

  .bm-burger-bars {
    background: #676767;
  }

  .bm-burger-button {
    position: relative;
    width: 26px;
    height: 20px;
  }

  .bm-overlay {
    top: 0;
    left: 0;
  }

  .bm-menu-wrap {
    background: white;
    top: 0;
    left: 0;
  }

  .bm-cross {
    height: 20px !important;
  }

  .bm-cross {
    background: #676767;
  }

  .bm-item-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0 4%;
  background-color: #fffefe;

  ${BurgerMenuWrapper} {
    display: none;
  }

  @media only screen and (min-width: 599px) {
    padding: 0 max(50px, 10%);
  }

  @media only screen and (min-width: 799px) {
    padding: 0 max(50px, 15%);
  }

  @media only screen and (max-width: 1000px) {
    ${CategoryRow} {
      display: none;
    }

    ${BurgerMenuWrapper} {
      display: flex;
      align-items: center;
    }
  }
`

const NavLink = styled.div<{ selected?: boolean }>`
  transition: 0.2s ease-out;
  padding: 20px;
  color: ${(props) => (props.selected ? props.theme.colors.black : props.theme.colors.secondary)};
  font-size: ${(props) => props.theme.font.large};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text_primary_hover};
  }
`

const CartCount = styled.div<{ visible: boolean }>`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.font.large};
  position: absolute;
  top: 6px;
  right: 7px;
  transition: 0.2s opacity;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

const ShadowContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s all;
  ${({ visible }) =>
    visible &&
    css`
      box-shadow: 0 15px 20px -20px gray;
    `}
  z-index:-1;
`

const StickyContainer = styled.div`
  position: sticky;
  top: -1px;
  left: 0;
  background-color: #fffefe;
  z-index: 999;
  overflow-y: visible;

  * {
    box-sizing: border-box;
  }
`
