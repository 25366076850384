import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
// @ts-ignore
import LazyLoad from 'react-lazyload'
import { openPopup } from '../actions/ui'
import { getImagePath } from '../utilities/getImagePath'
import { getIsProductSelected } from '../utilities/selectors/getIsProductSelected'
import AddButton from './AddButton'
import { PopupTypes } from './popup/index'
import { ProductDetailPopupProps } from './popup/ProductDetailPopup'
import { State } from '../types'
import { getStoredUserInfo } from '../utilities/selectors/getStoredUserInfo'

export type ProductProps = ProductDetailPopupProps & {
  columns?: number
  show?: boolean
  tablet?: number
  mobile?: number
}

const Product: React.FC<ProductProps> = (props) => {
  const { price, weight, id, image1, toggle, columns = 5, tablet = columns, mobile = columns, show = true } = props
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  // const selected = useSelector((state: State) => getIsProductSelected(state)(id))
  const { showPrice = false } = useSelector((state: State) => ({
    showPrice: getStoredUserInfo(state).activeCategory.includes('showprice'),
  }))

  if (!show) {
    return null
  }

  const openPopupClick = () => {
    dispatch(openPopup({ type: PopupTypes.PRODUCT_DETAIL_POPUP, payload: { ...props } }))
  }

  return (
    <Container
      className="product-panel"
      id={id}
      width={100 / columns}
      tablet={100 / tablet}
      mobile={100 / mobile}
      onClick={openPopupClick}
    >
      <ImageWrapper style={{ pointerEvents: 'none' }} loading={loading}>
        <LazyLoad once>
          <Image
            alt="product"
            src={getImagePath(image1)}
            onLoad={() => {
              setLoading(false)
            }}
          />
        </LazyLoad>
      </ImageWrapper>
      <InfoContainer style={{ pointerEvents: 'none' }}>
        {showPrice && price && <Label style={{ paddingBottom: '0' }}>{`$${price}`}</Label>}
        <Label style={{ whiteSpace: 'nowrap', paddingBottom: '15px' }}>{id}</Label>
        {/* <AddButton
          className="product-panel"
          id={id}
          style={{ pointerEvents: 'auto' }}
          selected={selected}
          onClick={toggle}
        /> */}
      </InfoContainer>
    </Container>
  )
}

export default Product

const Container = styled.div<{ width: number; tablet: number; mobile: number }>`
  width: calc(${({ width }) => width}% - 4px);
  padding: 15px;
  margin: 2px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.product_bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;

  @media only screen and (max-width: 1500px) {
    //special 1500px here
    width: calc(${({ tablet }) => tablet}% - 4px);
  }

  @media only screen and (max-width: 599px) {
    width: calc(${({ mobile }) => mobile}% - 4px);
  }
`

export const ImageWrapper = styled.div<{ loading: boolean }>`
  transition: 0.5s opacity;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
`

const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 87px;
`

const Label = styled.div<{ variant?: string }>`
  color: ${(props) => (props.variant === 'secondary' ? props.theme.colors.secondary : props.theme.colors.black)};
  font-size: ${(props) => props.theme.font.regular};
  text-align: center;
  padding: 5px 0;
  font-weight: bold;
  opacity: 80%;
`
