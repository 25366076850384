import directus, { checkError } from './directus'
import { CatalogueTable } from './getProductData'

export const getNavCategory = async (table: string) => {
  try {
    const res = await directus.items<string, CatalogueTable>(table).readMany({ fields: ['active_category'] })
    return res.data as unknown as CatalogueTable & { active_category: string[] }
  } catch (e) {
    checkError(e)
    throw e
  }
}
