import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { closePopup } from '../../actions/ui'
import Subheader from '../Subheader'

interface ConfirmPopupProps {
  title: string
  body: string
  onConfirm?: () => void
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ title, body, onConfirm = () => {} }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    onConfirm()
    dispatch(closePopup())
  }

  return (
    <Container>
      <Subheader bottom="30px">{title}</Subheader>
      <Text>{body}</Text>
      <ButtonContainer>
        <Button onClick={handleClick}>Ok</Button>
      </ButtonContainer>
    </Container>
  )
}

export default ConfirmPopup

const Container = styled.div`
  width: 400px;
  padding: 20px 20px 35px 20px;
  background-color: #fffefe;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`

const Text = styled.div`
  font-size: ${(props) => props.theme.font.large};
`

const Button = styled.div`
  width: 80px;
  height: 30px;
  box-sizing: border-box;
  line-height: 14px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 8px;
  border-radius: 7px;
  font-size: ${(props) => props.theme.font.regular};
  font-weight: 700;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
`
