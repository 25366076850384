import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Header from './components/Header'
import NavBar from './components/NavBar'
import Home from './containers/Home'
import Layout from './containers/Layout'
import Popup from './components/popup/Popup'
import WithUserInfo from './containers/WithUserInfo'
import MainCatalogue from './containers/MainCatalogue'
import withCatalogueRoutes from './hoc/withCatalogueRoutes'
import Summary from './containers/Summary'
import NotFound from './containers/NotFound'
import Redirect from './components/Redirect'
import './App.css'
import { useSelector } from 'react-redux'
import { getStoredUserInfo } from './utilities/selectors/getStoredUserInfo'

function App() {
  const Catalogue = withCatalogueRoutes(MainCatalogue)
  const { offer = false } = useSelector(getStoredUserInfo)

  return (
    <>
      <Popup />
      <WithUserInfo>
        <BrowserRouter>
          <Header />
          <NavBar />
          <Routes>
            <Route path="/:key" element={<Redirect />} />
            <Route
              path="/:key/catalogue/home"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            {!offer && (
              <Route
                path="/:key/catalogue/:category"
                element={
                  <Layout>
                    <Catalogue />
                  </Layout>
                }
              />
            )}
            {/* <Route
              path="/:key/summary"
              element={
                <Layout>
                  <Summary />
                </Layout>
              }
            /> */}
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </BrowserRouter>
      </WithUserInfo>
    </>
  )
}

export default App
