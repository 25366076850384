import * as R from 'ramda'
import { ProductConfig } from '../containers/Home'

const RELATED_STYLE_PATH = 'style_group.related_styles'
const CONSTANT_SILVER_SPOT = 25

//apply discount and make the price and weight 2 decimal
export const normalizeTableData = (
  array: Record<string, any>[],
  key: string,
  discount?: number,
  spot: number = CONSTANT_SILVER_SPOT,
) => {
  if (!discount && !spot) {
    return R.map((value) => R.prop(key, value), array)
  }

  //fix weight and price to 2 decimal + apply discount
  const cleanWeightAndPrice = (product: ProductConfig) => {
    let cleanProduct = { ...product }
    const weight = Number(product.weight) || 0
    if (weight) {
      cleanProduct = R.assoc('weight', weight.toFixed(2), cleanProduct)
    }

    const price = Number(product.price)
    if (price) {
      const adjustedPrice = price + (spot - CONSTANT_SILVER_SPOT) * 0.03215 * weight
      cleanProduct = R.assoc(
        'price',
        (Math.round((discount ? ((100 - discount) / 100) * adjustedPrice : adjustedPrice) * 10) / 10).toFixed(2), //round to nearest 0.1
        cleanProduct,
      )
    }
    return cleanProduct
  }

  return R.map((value) => {
    return R.pipe<any, ProductConfig, ProductConfig>(R.prop(key), (product) => {
      const cleanProduct = cleanWeightAndPrice(product)
      const relatedStyles = cleanProduct.style_group?.related_styles
      if (relatedStyles) {
        return R.assocPath(
          RELATED_STYLE_PATH.split('.'),
          R.map(cleanWeightAndPrice, relatedStyles as any),
          cleanProduct,
        )
      }

      return cleanProduct
    })(value)
  }, array)
}

// [{a: {b: 2}}] => [2]
export const flattenObjectLayer = (array: Record<string, any>[], path: string): any[] =>
  R.map((value) => R.pathOr('', path.split('.'), value), array)
