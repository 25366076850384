import React, { useCallback, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
// @ts-ignore
import { SideBySideMagnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers'
import * as R from 'ramda'
import { TbArrowsDiagonalMinimize2, TbArrowsDiagonal } from 'react-icons/tb'
import AddButton from '../AddButton'
import { useDispatch, useSelector } from 'react-redux'
import { closePopup } from '../../actions/ui'
import { getImagePath } from '../../utilities/getImagePath'
import { getIsProductSelected } from '../../utilities/selectors/getIsProductSelected'
import { State } from '../../types'
import { toggleItem, toggleOfferItem } from '../../actions/data'
import { getStoredUserInfo } from '../../utilities/selectors/getStoredUserInfo'

export type ProductDetailPopupProps = ProductDetails & {
  style_group: {
    related_styles?: ProductDetails[]
  }
}

export interface ProductDetails {
  id: string
  description: string
  metal: string
  weight: string
  image1: string
  toggle: () => void
  price?: string
  plating: string
  texture: string
}

const productType: any = {
  1: 'Ring',
  2: 'Earring',
  4: 'Pendant',
  '60': 'Bangle',
  '61': 'Bangle',
  '62': 'Bracelet',
  '63': 'Bracelet',
  '64': 'Bracelet',
  '65': 'Bangle',
  '66': 'Bangle',
  '67': 'Bracelet',
  '68': 'Bangle',
  BE: 'Earring',
  BP: 'Pendant',
  BN: 'Necklace',
}

//take the thing out at props
//check the exit icon
const ProductDetailPopup: React.FC<ProductDetailPopupProps> = (props) => {
  const { id, style_group } = props
  const dispatch = useDispatch()
  const {
    isSelected,
    spot,
    offer = false,
    showPrice = false,
  } = useSelector((state: State) => ({
    isSelected: getIsProductSelected(state),
    spot: state.user.spot,
    offer: state.user.offer,
    showPrice: getStoredUserInfo(state).activeCategory.includes('showprice'),
  }))
  const [expand, setExpand] = useState(false)
  const [animate, setAnimate] = useState(false)
  const [zoom, setZoom] = useState(false)
  const [loading, setLoading] = useState(true)
  const relatedStyles = R.filter((relatedStyle) => relatedStyle.id !== id, style_group?.related_styles || [])
  const hasRelatedStyles = relatedStyles.length > 0

  const handleAdd = useCallback((product: ProductDetails) => {
    dispatch(offer ? toggleOfferItem(product) : toggleItem(product))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getProductSets = (productDetails: ProductDetailPopupProps[] | ProductDetails[], divider?: boolean) => {
    return R.map(({ id, description, metal, weight, price, image1, toggle, plating, texture }) => {
      const previewImage = getImagePath(image1)

      return (
        <React.Fragment key={id}>
          {divider && (
            <>
              <ScrollableDividerOne />
              <ScrollableDividerTwo />
            </>
          )}
          <GalleryContainer
            expand={expand}
            onClick={() => setZoom(!zoom)}
            zoom={zoom}
            onMouseLeave={() => setZoom(false)}
          >
            <MainImageContainer>
              <MainImage
                loading={loading}
                onImageLoad={() => setLoading(false)}
                alwaysInPlace={true}
                imageSrc={previewImage}
                imageAlt="main"
                largeImageSrc={previewImage}
                mouseActivation={MOUSE_ACTIVATION.CLICK}
                touchActivation={TOUCH_ACTIVATION.TAP}
              />
            </MainImageContainer>
          </GalleryContainer>
          <InfoContainer expand={expand}>
            <Label>
              <Description>Item Code: </Description>
              <Value>{id}</Value>
            </Label>
            {/* {description && (
              <Label>
                <Description>Description: </Description>
                <Value>{description}</Value>
              </Label>
            )} */}
            <Label>
              <Description>Description: </Description>
              <Value>{`${metal} ${productType[id.slice(0, 1)] || productType[id.slice(0, 2)] || ''}`}</Value>
            </Label>
            {/* <Label>
              <Description>Plating: </Description>
              <Value>{plating}</Value>
            </Label>
            <Label>
              <Description>Texture: </Description>
              <Value>{texture}</Value>
            </Label>
            <Label>
              <Description>Metal: </Description>
              <Value>{metal}</Value>
            </Label> */}
            <Label>
              <Description>Weight: </Description>
              <Value>
                {weight} {'grams'}
              </Value>
            </Label>
            {showPrice && price && (
              <Label>
                <Description>Price: </Description>
                <Value>{`$${price}`}</Value>
              </Label>
            )}
            {/* <AddButton style={{ marginTop: expand ? '10px' : '20px' }} onClick={toggle} selected={isSelected(id)} /> */}
            {showPrice && spot && (
              <InfoText>
                *Price is in <span>USD</span> and is based on silver spot <span>${spot}.00</span>
              </InfoText>
            )}
          </InfoContainer>
        </React.Fragment>
      )
    }, productDetails)
  }

  return (
    <PopupContainer expand={expand} animate={animate} onAnimationEnd={() => setAnimate(false)}>
      <Exit withScroll={expand} onClick={() => dispatch(closePopup())}>
        X
      </Exit>
      <ScrollableWrapper>
        {getProductSets([props])}
        {expand &&
          hasRelatedStyles &&
          getProductSets(
            R.map<ProductDetails, ProductDetails>((relatedStyle) => {
              return { ...relatedStyle, toggle: () => handleAdd(relatedStyle) }
            }, relatedStyles),
            true,
          )}
      </ScrollableWrapper>
      {hasRelatedStyles && (
        <ButtonDeadZone>
          <ExpandButton
            expand={expand}
            onClick={() => {
              setExpand(!expand)
              setAnimate(true)
            }}
          >
            {expand ? (
              <>
                <>Minimize</>
                <TbArrowsDiagonalMinimize2 size="16px" />
              </>
            ) : (
              <>
                <>See Similar</>
                <TbArrowsDiagonal size="16px" stroke="#FFFEFE" />
              </>
            )}
          </ExpandButton>
        </ButtonDeadZone>
      )}
    </PopupContainer>
  )
}

export default ProductDetailPopup

const expandAnimate = keyframes`
  0%
  {
    opacity: 0%;
  }
  100%
  {
    opacity: 100%;
  }
`

const ButtonDeadZone = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(calc(-50% + 15px));
  padding: 10px 30px 20px 0;
  z-index: 999;
`

const ExpandButton = styled.div<{ expand: boolean }>`
  border-radius: 20px;
  background-color: ${({ expand }) => (expand ? '#dbdbdb' : '#434343')};
  width: ${({ expand }) => (expand ? '90px' : '100px')};
  height: 26px;
  color: ${({ theme, expand }) => (expand ? theme.colors.primary : '#FFFEFE')};
  padding: 4px 6px 4px 10px;
  font-size: ${(props) => props.theme.font.regular};
  font-weight: 700;
  opacity: 80%;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;

  * {
    padding: 0 2px;
  }

  transition: 0.1s all ease-out;

  :hover {
    box-shadow: 0 0.3em 0.3em -0.2em black;
    transform: translateY(-2px);
  }
`

const ScrollableDividerOne = styled.div`
  width: 50%;
  background-color: #fffefe;
  height: 4px;
`

const ScrollableDividerTwo = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.colors.product_bg};
  height: 4px;
`

const ScrollableWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
`

const InfoText = styled.div`
  position: absolute;
  bottom: 4px;
  right: -2px;
  font-size: 0.75em;
  color: ${({ theme }) => theme.colors.primary};
  opacity: 85%;
  text-align: center;
  width: 100%;

  span {
    font-weight: bold;
  }
`

const GalleryContainer = styled.div<{ expand: boolean; zoom: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: ${({ expand }) => (expand ? '50%' : '60%')};
  margin: 0 -2px;
  box-sizing: border-box;

  &:hover {
    cursor: ${({ zoom }) => (zoom ? 'crosshair' : ' zoom-in')};
  }
`

const InfoContainer = styled.div<{ expand: boolean }>`
  width: ${({ expand }) => (expand ? '50%' : '40%')};
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  width: 40%;
  text-align: right;
  font-size: ${({ theme }) => theme.font.regular};
  padding-right: 10px;
  box-sizing: border-box;
`

const Value = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  width: 60%;
  font-size: ${({ theme }) => theme.font.regular};
`

const PopupContainer = styled.div<{ expand: boolean; animate: boolean }>`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px ${({ theme }) => theme.colors.grey};
  width: ${({ expand }) => (expand ? 'max(650px, 40%)' : 'max(690px, 40%)')};
  min-width: ${({ expand }) => (expand ? '650px' : '690px')};
  background-color: #fffefe;
  overflow: hidden;
  display: flex;
  background-color: #fffefe;
  max-height: 80%;
  border-radius: 12px;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${expandAnimate} 0.5s cubic-bezier(0.39, 0.24, 0.2, 1.06);
    `}

  @media only screen and (max-width: 799px) {
    width: min(80%, 450px);
    min-width: unset;

    /* height: 94%; */ //so in stack mode the white info box isnt fullscreen
    max-height: unset;

    ${ButtonDeadZone} {
      display: none;
    }

    ${GalleryContainer}, ${InfoContainer} {
      width: 100%;
    }

    ${InfoContainer} {
      height: 100%;
    }

    ${GalleryContainer} {
      margin: 0;
    }

    ${ScrollableWrapper} {
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: hidden;
    }

    ${Description}, ${Value} {
      width: 50%;
    }

    ${Description} {
      width: 50%;
    }
  }
`

const MainImage = styled(SideBySideMagnifier)<{ loading: boolean }>`
  width: 100%;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  transition: 0.2s opacity;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
`

const MainImageContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.product_bg};

  &::after {
    content: '';
    display: block;
    margin-top: 100%;
  }
`

const Exit = styled.div<{ withScroll: boolean }>`
  position: absolute;
  right: ${({ withScroll }) => (withScroll ? '13px' : '9px')};
  top: ${({ withScroll }) => (withScroll ? '8px' : '5px')};
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  font-weight: 700;
  z-index: 999;
`

const Label = styled.div`
  display: flex;
  width: 100%;
  margin: 5px 0;
`
