import styled from 'styled-components'
import Footer from '../components/Footer'

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <StyledLayout>
        <div>{children}</div>
        <Footer />
      </StyledLayout>
    </>
  )
}

const StyledLayout = styled.div`
  /* min-width: 800px; */
  min-height: calc(100vh - 170px);
  padding: 0 4%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  @media only screen and (min-width: 599px) {
    padding: 0 max(50px, 10%);
  }

  @media only screen and (min-width: 799px) {
    padding: 0 max(50px, 15%);
  }
`

export default Layout
