import styled from 'styled-components'

const Footer: React.FC = () => {
  return <Text>© 2024 Benson Jewelry Co. Ltd. All rights reserved.</Text>
}

export default Footer

const Text = styled.div`
  font-size: ${({ theme }) => theme.font.regular};
  color: ${({ theme }) => theme.colors.primary};
  opacity: 60%;
  padding: 15px;
  margin-top: 100px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
`
