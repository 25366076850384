import directus, { checkError } from './directus'
import { CatalogueTable } from './getProductData'

export const getAllCount = async (table: string, field: string) => {
  try {
    const res = await directus.items<string, CatalogueTable>(table).readMany({
      fields: [field],
      deep: { [field]: { _limit: -1 } },
    })

    return (res.data?.[field as any] as any).length || 0
  } catch (e) {
    checkError(e)
    throw e
  }
}
