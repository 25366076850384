import { ThemeProvider, DefaultTheme } from 'styled-components'

interface ThemeProps {
  children: JSX.Element | JSX.Element[]
}

const Theme = ({ children }: ThemeProps): any => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme

const theme: DefaultTheme = {
  colors: {
    primary: '#2e2e2e', //4B4B4B //last was #555555
    primary_hover: '#363636',
    text_primary_hover: '#a0a0a0',
    header_primary: '#24385B',
    secondary: '#555555', //897358 off gold
    grey: '#CFCFCF',
    grey_hover: '#c0c0c0',
    grey_disabled: '#e8e8e8',
    red: 'red',
    black: 'black',
    green: '#5bbd00',
    product_bg: '#F6F6F6',
    popup_bg: 'rgba(220, 220, 220, 0.5)',
  },
  font: {
    header: '45px',
    subHeader: '35px', //
    bodyHeader: '25px', //
    large: '15px',
    regular: '13px',
  },
}
