export enum Path {
  Home = '/catalogue/home',
  Rings = '/catalogue/rings',
  Necklaces = '/catalogue/necklaces',
  Bracelets = '/catalogue/bracelets',
  Earrings = '/catalogue/earrings',
  Pendants = '/catalogue/pendants',
  Summary = '/summary',
  NotFound = '/404',
}
