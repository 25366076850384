import { CLOSE_POPUP, OPEN_POPUP } from '../actions/constants'

export interface PayloadProps {
  type: string
  payload: Record<string, any>
}

const initialState = {}

const popupReducer = (state = initialState, { type, payload }: PayloadProps) => {
  switch (type) {
    case OPEN_POPUP:
      return { ...payload }
    case CLOSE_POPUP:
      return initialState
    default:
      return state
  }
}

export default popupReducer
