import { ProductDetailPopupProps, ProductDetails } from '../components/popup/ProductDetailPopup'
import { ProductConfig } from '../containers/Home'
import { CLEAR_OFFER, CLEAR_PRODUCTS, SET_USER_INFO, TOGGLE, TOGGLE_OFFER } from './constants'

export const toggleItem = (payload: ProductConfig | ProductDetailPopupProps | ProductDetails) => ({
  type: TOGGLE,
  payload,
})

export const toggleOfferItem = (payload: ProductConfig | ProductDetailPopupProps | ProductDetails) => ({
  type: TOGGLE_OFFER,
  payload,
})

export interface setUserInfoPayload {
  offer: boolean
  table: string
  spot: number
  discount: number
  expire: string
  activeCategory: string[]
  productKey?: string
  offerKey?: string
}

export const setUserInfo = (payload: setUserInfoPayload) => ({
  type: SET_USER_INFO,
  payload,
})

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS,
})

export const clearOffers = () => ({
  type: CLEAR_OFFER,
})
