/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { Checkbox } from '@chakra-ui/react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Player } from '@lottiefiles/react-lottie-player'
import Product from '../components/Product'
import Subheader from '../components/Subheader'
import { toggleItem } from '../actions/data'
import { getStoredUserInfo } from '../utilities/selectors/getStoredUserInfo'
import { CatalogueTable, getProductData } from '../services/getProductData'
import { normalizeTableData } from '../utilities/normalizeTableData'
import { ProductConfig } from './Home'
import Divider from '../components/Divider'
import { getFilter, getFilterPayload } from '../services/getFilter'
import { closePopup, openPopup } from '../actions/ui'
import { PopupTypes } from '../components/popup'
import { wait } from '../utilities/wait'
import { getCurrentQuery } from '../utilities/getCurrentQuery'
import Loading from '../assets/infinite-loading.json'
import { prepFilterForQuery } from '../utilities/prepFilterForQuery'
import { getAllCount } from '../services/getAllCount'

export interface MainCatalogueProps {
  catalogueField: string //which field of the category to select from directus table
  tagType: string
  title: string
  catalogueTable: keyof CatalogueTable
}

const OFFSET = 20

const MainCatalogue: React.FC<MainCatalogueProps> = ({ catalogueField, tagType, title, catalogueTable }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [infiniteLoadingAnimation, setInfiniteLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<ProductConfig[]>([])
  const stateRef = useRef(products)
  const setProductsState = (newState: ProductConfig[]) => {
    stateRef.current = newState
    setProducts(newState)
  }
  const [filter, setFilter] = useState<getFilterPayload['category']>([])
  const [searchParams, setSearchParams] = useSearchParams()
  // const query = Array.from(searchParams.keys())
  const location = useLocation()
  const [selectedFilter, setSelectedFilter] = useState<Record<string, any>>(
    Array.from(searchParams.entries()).reduce((acc, value) => {
      acc[value?.[1]] = Number(value[0] || 0)
      return acc
    }, {} as Record<string, any>),
  )

  // const applyDisabled = R.equals(query, R.keys(prepFilterForQuery(selectedFilter)).sort())

  const dispatch = useDispatch()
  const init = useRef(false)
  const infiniteLoading = useRef(false)
  const offset = useRef(OFFSET)
  const allCount = useRef(0)
  const infiniteLoadingEnabled = useRef(true)
  const { table = '', discount, spot } = useSelector(getStoredUserInfo)

  const normalizeAndSetProduct = (data: any, merge = true) => {
    const normalizedProducts = normalizeTableData(
      data[catalogueTable],
      `${R.last(R.split('.', catalogueField))}`,
      discount,
      spot,
    )

    const mergedProducts = [...stateRef.current, ...normalizedProducts]

    //if current selections and filters has reached end of infinite
    if (mergedProducts.length >= data.length || normalizedProducts.length === 0) {
      infiniteLoadingEnabled.current = false
    }

    setProductsState(merge ? mergedProducts : normalizedProducts)
  }

  useEffect(() => {
    ;(async () => {
      try {
        dispatch(openPopup({ type: PopupTypes.LOADING_POPUP }))
        //when at the end and finish
        window.onscroll = async () => {
          if (
            infiniteLoadingEnabled.current &&
            window.innerHeight + Math.ceil(window.pageYOffset) >= document.body.offsetHeight - 20 &&
            !infiniteLoading.current
          ) {
            infiniteLoading.current = true
            setInfiniteLoading(true)
            await wait()
            const lazyLoadedProducts = await getProductData(table, [catalogueField], offset.current, getCurrentQuery())
            normalizeAndSetProduct(lazyLoadedProducts)
            offset.current = offset.current + OFFSET
            setInfiniteLoading(false)
            infiniteLoading.current = false
          }
        }
        await wait(300)
        // const [data, filterData] = await Promise.all([
        //   getProductData(table, [catalogueField], 0, getCurrentQuery()),
        //   getFilter(tagType),
        // ])
        const [data, count] = await Promise.all([
          getProductData(table, [catalogueField], 0, getCurrentQuery()),
          getAllCount(table, catalogueTable),
        ])

        allCount.current = count

        normalizeAndSetProduct(data)
        // setFilter(filterData.category.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1)))
      } finally {
        setLoading(false)
        dispatch(closePopup())
      }
    })()

    return () => {
      window.onscroll = null
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!init.current) {
        init.current = true
      } else {
        try {
          dispatch(openPopup({ type: PopupTypes.LOADING_POPUP }))
          infiniteLoadingEnabled.current = true
          await wait()
          offset.current = OFFSET
          const lazyLoadedProducts = await getProductData(table, [catalogueField], 0, getCurrentQuery())
          normalizeAndSetProduct(lazyLoadedProducts, false)
        } finally {
          dispatch(closePopup())
        }
      }
    })()
  }, [location.search])

  const handleAdd = useCallback((product: ProductConfig) => {
    dispatch(toggleItem(product))
  }, [])

  const handleClickFilter = useCallback(
    (group, filterId) => {
      const temp = { ...selectedFilter }
      if (temp[group] === filterId) {
        delete temp[group]
      } else {
        temp[group] = filterId
      }
      setSelectedFilter(temp)
    },
    [selectedFilter],
  )

  if (loading) {
    return null
  }

  const segmentedFilter: Record<string, any> = {}

  filter.forEach((item) => {
    const group = item.name.substring(0, 1)
    segmentedFilter[group] = [...(segmentedFilter[group] || []), item]
  })

  return (
    <>
      <Subheader>{title}</Subheader>
      {allCount.current && <AllCountHeader>( {allCount.current} listings )</AllCountHeader>}
      <FlexContainer style={{ marginBottom: '100px' }}>
        {/* {!R.isEmpty(filter) && (
          <Sidebar>
            <ResponsiveFlex>
              <ClearAll
                onClick={() => {
                  setSearchParams({})
                  setSelectedFilter({})
                }}
              >
                X CLEAR ALL
              </ClearAll>
              <ApplyButton
                disabled={applyDisabled}
                onClick={() => !applyDisabled && setSearchParams(prepFilterForQuery(selectedFilter))}
              >
                Apply
              </ApplyButton>
            </ResponsiveFlex>
            <Divider height="50px" />
            <SidebarSectionTitle>FILTER</SidebarSectionTitle>
            <CheckboxGroupFlex>
              {R.values(segmentedFilter).map((sfilter: any[], index, arr) => {
                return (
                  <>
                    <CheckboxGroup>
                      {sfilter.map((item: any) => {
                        const group = item.name.substring(0, 1)
                        return (
                          <StyledCheckBox
                            key={item.id}
                            colorScheme="gray"
                            onChange={() => handleClickFilter(item.name.substring(0, 1), item.id)}
                            isChecked={selectedFilter[group] === item.id}
                          >
                            {item.name.substring(1)}
                          </StyledCheckBox>
                        )
                      })}
                    </CheckboxGroup>
                    {index + 1 < arr.length && <Divider className="horizontal-divider" height="30px" lite />}
                  </>
                )
              })}
            </CheckboxGroupFlex>
          </Sidebar>
        )} */}
        {products.length === 0 && !loading ? (
          <>No items match the selected filters.</>
        ) : (
          <Row style={{ position: 'relative' }}>
            {products.map((product) => {
              return (
                <Product
                  columns={4}
                  tablet={3}
                  mobile={2}
                  {...product}
                  key={product.id}
                  toggle={() => handleAdd(product)}
                />
              )
            })}
            {infiniteLoadingAnimation && (
              <InfiniteLoadingContainer>
                <Player autoplay loop src={Loading} style={{ height: '80px', width: '80px' }}></Player>
              </InfiniteLoadingContainer>
            )}
            {!infiniteLoadingEnabled.current && (
              <InfiniteLoadingContainer style={{ bottom: '-80px' }}>END.</InfiniteLoadingContainer>
            )}
          </Row>
        )}
      </FlexContainer>
    </>
  )
}

export default React.memo(MainCatalogue, () => true)

const AllCountHeader = styled.div`
  font-size: ${({ theme }) => theme.font.large};
  text-align: center;
  font-weight: 300;
  padding-bottom: 60px;
  margin-top: -50px;
`

const InfiniteLoadingContainer = styled.div`
  position: absolute;
  bottom: -110px;
  transform: translateX(-50%);
  left: 50%;
  text-align: center;
  font-size: ${(props) => props.theme.font.regular};
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2px 0 -2px;
  flex: 1;
  align-items: flex-start;
`

const StyledCheckBox = styled(Checkbox)`
  && span {
    box-shadow: unset;
    font-size: ${(props) => props.theme.font.large};
  }
  padding: 2px 0;
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const SidebarSectionTitle = styled.div`
  font-size: ${(props) => props.theme.font.large};
  margin-bottom: 15px;
`

const CheckboxGroupFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Sidebar = styled.div`
  position: sticky;
  top: 120px;
  height: 100%;
  width: min(25%, 300px);
  padding: 0 40px 0 20px;
  box-sizing: border-box;

  @media only screen and (max-width: 1000px) {
    //remove
    width: 100%;
    position: relative;
    top: 0;
    margin-bottom: 50px;
    padding: 0 20px;

    .horizontal-divider {
      display: none;
    }

    ${CheckboxGroupFlex} {
      flex-direction: row;
      justify-content: space-around;
    }

    ${SidebarSectionTitle} {
      text-align: center;
    }
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ResponsiveFlex = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1500px) {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
`

const ClearAll = styled(SidebarSectionTitle)`
  cursor: pointer;
  margin-bottom: 0;

  @media only screen and (max-width: 1500px) {
    margin-bottom: 15px;
  }
`

const ApplyButton = styled.div<{ disabled: boolean }>`
  transition: 0.2s all;
  width: 60px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 3px;
  border-radius: 7px;
  font-size: ${(props) => props.theme.font.regular};
  font-weight: 700;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && theme.colors.primary_hover};
    transition: 0.3s;
  }
`
