import { Directus } from '@directus/sdk'
import { Path } from '../types/path'

const directus = new Directus(process.env.REACT_APP_ASSET_PATH || '')

export default directus

export interface Error {
  response: { status: number }
}

export const checkError = (e: unknown) => {
  const errorStatus = (e as Error)?.response?.status
  if (errorStatus === 401 || errorStatus === undefined) {
    const path = window.location.pathname
    if (path !== Path.NotFound) {
      window.location.href = Path.NotFound
    }
    throw e
  }
}
