import { setUserInfoPayload } from '../actions/data'
import { ProductDetailPopupProps } from '../components/popup/ProductDetailPopup'

export interface State {
  popup: {
    type: string
    payload: Record<string, unknown>
  }
  product: Record<string, ProductDetailPopupProps>
  offer: Record<string, ProductDetailPopupProps>
  user: setUserInfoPayload
}

export enum CatalogueFields {
  HIGHLIGHT = 'highlight.highlight_catalogue_id',
  OFFER = 'offer.catalogue_id',
  RING = 'ring.ring_catalogue_id',
  NECKLACE = 'necklace.necklace_catalogue_id',
  BRACELET = 'bracelet.bracelet_catalogue_id',
  EARRING = 'earring.earring_catalogue_id',
  PENDANT = 'pendant.pendant_catalogue_id',
}

export enum TagTypes {
  RING = 'ring',
  NECKLACE = 'necklace',
  BRACELET = 'bracelet',
  EARRING = 'earring',
  PENDANT = 'pendant',
}

export enum DirectusTable {
  FILTER = 'filter',
}
