import * as R from 'ramda'
import { CLEAR_PRODUCTS, PURGE_PRODUCTS, TOGGLE } from '../actions/constants'
import { ProductDetailPopupProps } from '../components/popup/ProductDetailPopup'

export interface PayloadProps {
  type: string
  payload: ProductDetailPopupProps
}

const initialState: Record<string, ProductDetailPopupProps> = {}

const productReducer = (state = initialState, { type, payload }: PayloadProps) => {
  switch (type) {
    case TOGGLE: {
      const keys = R.keys(state)
      const newState = { ...state }
      const { id } = payload

      return keys.includes(id) ? R.dissoc(id, newState) : R.assoc(id, payload, newState)
    }
    case PURGE_PRODUCTS:
    case CLEAR_PRODUCTS:
      return initialState
    default:
      return state
  }
}

export default productReducer
