import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import popupTypes, { PopupTypes } from '.'
import { State } from '../../types'
import PopupBase from './PopupBase'

interface PopupProps {
  className?: string
}

const Popup: React.FC<PopupProps> = () => {
  const { type, payload } = useSelector((state: State) => state.popup)

  useEffect(() => {
    const body = document.querySelector('body') as any

    if (type && popupTypes[type]) {
      // body.style.overflow = 'hidden'
    }

    return () => {
      // body.style.overflow = 'auto'
    }
  }, [type])

  if (!type || !popupTypes[type]) return null

  const PopupComponent = popupTypes[type]

  return (
    <PopupBase allowClose={type === PopupTypes.LOADING_POPUP ? false : true}>
      <PopupComponent {...payload} />
    </PopupBase>
  )
}

export default Popup
