import { PURGE_OFFER, PURGE_PRODUCTS, SET_USER_INFO } from '../actions/constants'
import { setUserInfoPayload } from '../actions/data'

export interface PayloadProps {
  type: string
  payload: setUserInfoPayload
}

const initialState = {}

const userReducer = (state = initialState, { type, payload }: PayloadProps) => {
  switch (type) {
    case SET_USER_INFO:
      return { ...state, ...payload }
    case PURGE_PRODUCTS:
    case PURGE_OFFER:
      return initialState
    default:
      return state
  }
}

export default userReducer
