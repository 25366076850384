import directus, { checkError } from './directus'

const login = async (key: string = '') => {
  try {
    localStorage.removeItem('auth_token')

    const res = await directus.auth.static(key)
    return res
  } catch (e) {
    checkError(e)
  }
}

export default login
